$(function () {
	$('.header__burger').on('click', function () {
		$('body').toggleClass('no-scroll');
		$('.header').toggleClass('show-menu');
	});

	$('.scroll-link').on('click', function (e) {
		e.preventDefault();

		$('body').removeClass('no-scroll');
		$('.header').removeClass('show-menu');

		const DELTA_OFFSET = $(this).data('offset') ? parseInt($(this).data('offset')) : 0;
		const offset = $($(this).attr('href')).offset().top - $('.header').height() - DELTA_OFFSET;
		$('html').animate({ scrollTop: offset + 'px' }, '300');
	});
});